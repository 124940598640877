<template>
  <div class="container mt-5">

    <h1>Privacy Policy</h1>

    <p>If you would like to go directly to “Your Privacy Rights, Choice and Access”, please go to section 5 of this
      policy. If you would like to go directly to “Managing Cookies (“Opt-Out”)”, please go to section 6 of this
      policy.</p>

    <p>This privacy policy (“Privacy Policy”) applies to information collected through this site (“we”, “us” or “our”),
      whether accessed via computer, mobile device or other technology (collectively, the “Service”).</p>

    <p>{{ appName }} is an e-commerce site.</p>

    <p>Generally {{ appName }} does not collect, store, use or disclose any data collected with an intention to
      personally identify and/or contact a person such as: name, address, telephone number or e-mail address except in
      specific instances, for example, when you asking to receive further information regarding one of the products or
      other offers listed on the Site, or as set forth in this Privacy Policy.</p>

    <p>This Privacy Policy explains what information we may collect through the Service, how such information may be
      used and/or shared with others, how we safeguard it and how you may access and control its use in connection with
      our marketing communications and activities.</p>

    <p>Please read this Privacy Policy carefully, prior to using the Service, and/or accessing the Site.
      By using the Service, and/or accessing the Site, you are acknowledging that you understand and agree to the terms
      hereof as well as the Terms of Use set forth on the Site</p>

    <h2>TABLE OF CONTENTS</h2>

    <ol class="list-unstyled">
      <li>Information we collect</li>
      <li>How we use the Information we collect</li>
      <li>Sharing of Information</li>
      <li>Online Behavioral Advertising</li>
      <li>Your Privacy Rights, Choice and Access</li>
      <li>Managing Cookies (“Opt-Out”)</li>
      <li>Data Retention and Deletion</li>
      <li>Children</li>
      <li>Security of your Information</li>
      <li>Other Sites</li>
      <li>Consent to Processing and Transfer of Information</li>
      <li>Changes</li>
      <li>Contact Us</li>
    </ol>

    ---

    <h3>1. Information we collect</h3>

    <p>Whenever you visit or interact with the Service or Site, we, as well as similar third-party vendors and/or
      service providers alike, we may use a variety of technologies that automatically or passively collect information
      about how the Service is accessed and used (“Usage Information”).</p>

    <p>Usage Information may include, in part, browser type, operating system, device type, an estimation of your
      geographic location associated with your IP address, the page served, the time, referring URLs and other
      information normally transmitted in HTTP requests.</p>

    <p>This statistical data provides us with information about the use of the Service, such as how many visitors visit
      a specific webpage on which the Service is used, how long they stay on that page, the type of content on that
      page.</p>

    <p>Usage Information is generally non-identifying, but to the extent Usage Information may be associated with you as
      a specific and identifiable person (for example, because you have registered for the Service) and thereby provided
      us with personal information (such as name, e-mail address, telephone number or home address) we treat such
      information as “Personal Information” and will only use it for the purposes described in this Privacy Policy.</p>

    <p>We may also automatically collect the internet protocol (“IP “) address or other identifier (“Device Identifier
      “) for each computer, mobile device, technology or other device (collectively, “Device “) you use to access the
      Service.</p>

    <p>Our Service uses several different technologies to collect Usage Information, including Device Identifiers. These
      may include, without limitation:</p>

    <h4>Cookies</h4>

    <p>Cookies are small text files placed on a Device when the Device is used to visit the Service or the Site. We may
      place cookies or similar files on your Device for security purposes, to facilitate site navigation and to
      personalize your experience while using the Service, such as showing you specific offer(s) that we think will be
      of interest to you.</p>

    <p>Cookies allow us to collect technical and navigational information, such as browser type, time spent on the
      Service and pages visited. Cookies may include a Device Identifier along with information about the particular
      type of content in which you have shown interest. Cookies also allow us to select which content is most likely to
      appeal to you and display that content while you are using the Service.</p>

    <p>When interacting with the Service, if you click on a link to one of our promoted offer(s), the customer
      sponsoring the promoted link may place a cookie on your Device as well, in which case you will be subject to their
      privacy policy.</p>

    <p>Please note that you are subject to the privacy policies of each site you visit, in addition to ours. If you
      click on a link to visit a site where the Service is not installed, you are subject only to that site ’s privacy
      policy while visiting that site, and not to this Privacy Policy.</p>

    <p>If you would prefer not to accept cookies, you can do this by activating the settings on your browser that allows
      you to refuse the setting of all or some cookies.</p>

    <p>However, if you use your browser settings to block all cookies, please be aware that some functions and features
      of the Service may not work properly because we may not be able to recognize and associate you with your
      account.</p>

    <h4>Log Files</h4>

    <p>As is true of most web sites, we may gather certain information automatically and store it in log files.</p>

    <p>This information may include IP addresses, browser type, internet service provider, referring/exit pages,
      operating system, date/time stamp and clickstream data.
      We use this information, which does not identify individual users, to detect fraud, administer the site, to track
      users ’actions around the Site and to gather demographic information about our user base as a whole.</p>

    <h4>Pixels</h4>

    <p>A pixel tag is a transparent graphic image, sometimes called a web beacon or tracking beacon, placed on a web
      page or in an e-mail, which indicates that a page or e-mail has been viewed. In addition, a pixel tag may tell
      your browser to get content from another server.</p>
    <p>Our third-party partners/service providers may also use cookies, scripts, web beacons or other technologies for
      the purpose of targeting; tracking and analyzing your use of the Site or Services; and/or providing you with
      content, as follows:</p>

    <h4>Analytics</h4>

    <p>We work with companies that provide services which help us analyze your use of our Site or Services, for internal
      purposes, and to (anonymously) collect information about your online usage activities across our Site or
      Services.</p>

    <h4>Google Analytics</h4>

    <p>You can learn more about how Google uses data it collects via our use of Google Analytics, by visiting Google's
      Privacy Policy at: www.google.com/policies/privacy/partners</p>

    <h4>Social Buttons, Widgets, Applications and other Content</h4>

    <p>We may implement a social button, “widget” or other content from a third party on our Site or Services which
      allow interaction or content sharing. Your interaction with such, typically allows the third party to collect some
      information about you. In some cases, the third party may
      We may allow social buttons, applications, and widgets from the partners listed below (for more information,
      please click on the selected partner’s name to review its privacy policy) –</p>

    <ul>
      <li>Facebook</li>
      <li>YouTube</li>
      <li>Twitter</li>
    </ul>


    <h4>Advertising and Targeting</h4>

    <p>We allow certain companies to serve advertisements and/or personalized content and collect information within our
      Site or Services. These companies include third party ad servers, ad agencies, technology vendors, providers of
      sponsored content, research firms, and other companies that help us provide more effective advertising.</p>
    <p>The providers with whom we work include, but are not limited to, the companies listed below (for more
      information, please click on the selected partner’s name to review its privacy policy):</p>

    <ul>
      <li>Verizon (OATH, Yahoo)</li>
      <li>Bing</li>
      <li>Kelkoo</li>
      <li>Amazon</li>
    </ul>

    <h4>Information you provide to us</h4>

    <p>In addition to the information we collect automatically, we may ask you to provide Personal Information if you
      use the Service or otherwise communicate or interact with us.
      We may also ask you to provide other information about yourself, such as name, e-mail address and phone number. If
      you do not want your Personal Information collected, please do not submit it.</p>

    <h3>2. How we use the Information we collect</h3>

    <p>We use the information we collect about and from you for a variety of business purposes, including for example:
      to respond to your questions and requests; to provide you with access to certain functions and features of the
      Service; to provide and maintain your platform account(s); to verify your identity; to communicate with you about
      your account and activities using the Service and, in our sole discretion for marketing or solicitation purposes,
      to communicate changes to one of our policies; to tailor content or offer(s) we serve you; to improve the Service
      for internal business purposes; to process applications and transactions; and for other lawful purposes we may
      disclose at the time you provide your Personal Information or to which you otherwise consent.</p>

    <h3>3. Sharing of Information</h3>

    <p>We may disclose Personal Information as follows:</p>

    <ul>
      <li>To any current or future affiliates, parent companies, or subsidiaries;</li>
      <li>To vendors, service providers, agents, contractors, or others who perform functions (e.g., maintenance, data
        analysis, customer relationship management, email marketing, surveys, credit card processing, data hosting, or
        fraud detection) on our behalf;
      </li>
      <li>To Customers. For example, we may provide advertisers with information to inform them about conversion rates
        for analytics purposes;
      </li>
    </ul>

    <p>We may also disclose Personal Information when legally required to do so, to cooperate with law enforcement
      investigations or other legal proceedings, to protect against misuse or unauthorized use of the Website, to limit
      our legal liability and protect our rights or to protect the rights, property or safety of users of this Site, the
      Service or the public.</p>

    <h3>4. Online Behavioral Advertising</h3>

    <p>Through our Services, we may provide advertisements to you based on your recent browsing behavior. In order to
      target advertisements to you for products and services in which you might be interested, we may use cookies,
      JavaScript and other technologies.</p>

    <p>We may also use third parties such as network advertisers, which are third parties that display advertisements
      based on your visits to websites you have visited to target advertisements to you for products and services in
      which you might be interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement
      services may also use cookies, JavaScript, web beacons and other technologies to measure the effectiveness of
      their ads and to personalize advertising content to you. These third-party cookies and other technologies are
      governed by each third party ’s specific privacy policy, not this one.</p>

    <h3>5. Your Privacy Rights and Choices</h3>

    <p>We will provide reasonable opportunity for individuals to access, update, or delete Personal Information about
      them that we have in our possession. We will not use Personal Information provided to us for purposes incompatible
      with the purpose for which it was provided without obtaining authorization from the subject of the
      information.</p>
    <p>Individuals ’principal rights under data protection law are:</p>

    <ul>
      <li>the right to access</li>
      <li>the right to rectification</li>
      <li>the right to erasure</li>
      <li>the right to restrict processing</li>
      <li>the right to object to processing;</li>
      <li>the right to data portability;</li>
      <li>the right to complain to a supervisory authority; and</li>
      <li>the right to withdraw consent.</li>
    </ul>

    <p>You have the right to confirmation as to whether or not we process your Personal Information and, where we do,
      access to the Personal Information, together with certain additional information. That additional information
      includes details of the purposes of the processing, the categories of Personal Information concerned and the
      recipients of the Personal Information. Providing the rights and freedoms of others are not affected, we will
      supply to you a copy of your Personal Information. The first copy will be provided free of charge, but additional
      copies may be subject to a reasonable fee.</p>

    <p>Right to Erasure - In some circumstances you have the right to the erasure of your Personal Information. Those
      circumstances include: The Personal Information is no longer necessary in relation to the purposes for which it
      was collected or otherwise processed; you withdraw consent to consent-based processing; you object to the
      processing under certain rules of applicable data protection law; the processing is for direct marketing purposes;
      and the personal data have been unlawfully processed.</p>
    <p>There are exclusions of the right to erasure. The general exclusions include where processing is necessary: for
      exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the
      establishment, exercise or defense of legal claims.</p>

    <p>In some circumstances you have the right to restrict the processing of your personal data. Those circumstances
      are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer
      need the personal data for the purposes of our processing, but you require personal data for the establishment,
      exercise or defense of legal claims; and you have objected to processing, pending the verification of that
      objection.</p>

    <p>Where processing has been restricted on this basis, we may continue to store your Personal Information. However,
      we will only otherwise process it: with your consent; for the establishment, exercise or defense of legal claims;
      for the protection of the rights of another natural or legal person; or for reasons of important public
      interest.</p>

    <p>You have the right to object to our processing of your Personal Information for direct marketing purposes
      (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your
      personal data for this purpose.</p>

    <p>To the extent that the legal basis for our processing of your personal data is :</p>

    <p>1. consent; or</p>
    <p>2. that the processing is necessary for the performance of a contract to which you are party or in order to take
      steps at your request prior to entering into a contract, and such processing is carried out by automated means,
      you have the right to receive your Personal Information from us in a structured, commonly used and
      machine-readable format. However, this right does not apply where it would adversely affect the rights and
      freedoms of others.</p>

    <p>If you consider that our processing of your personal information infringes data protection laws, you have a legal
      right to lodge a complaint with a supervisory authority responsible for data protection.</p>
    <p>To the extent that the legal basis for our processing of your Personal Information is consent, you have the right
      to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the
      withdrawal.</p>
    <p>\*\*\* You may exercise any of your rights in relation to your Personal Information or send us an inquiry
      regarding your Privacy Rights and Choices by filling the following form https://forms.gle/cVpGHBwGmsWMfRU9A</p>

    <h4>Please note that:</h4>

    <p>1. It may take time to process email requests consistent with applicable law (but no longer than 30 days after
      receipt of a request); and</p>
    <p>2. You cannot “opt-out” of transactional or relationship messages sent to registered users of the Service or
      those who have engaged in transactions with us (e.g., account notifications).</p>

    <p>In accordance with our routine record keeping, we may delete certain records that contain Personal Information
      you have submitted through the Service. We are under no obligation to store such Personal Information indefinitely
      and disclaim any liability arising out of, or related to, the destruction of such Personal Information.</p>

    <p>In addition, you should be aware that it is not always possible to completely remove or delete all of your
      information from our databases without some residual data because of backups and other reasons.</p>

    <h3>6. Managing Cookies (“Opt-Out”)</h3>

    <p>If you do not want to receive interest-based ads displayed by us or otherwise object to collection of information
      based on our use of cookies, you can disable or delete them
      Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from
      browser to browser, and from version to version. You can however obtain up-to-date information about blocking and
      deleting cookies via these links:</p>

    <ul class="list-unstyled">
      <li>(a) https://support.google.com/chrome/answer/95647?hl=en (Chrome);</li>
      <li>(b) https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);</li>
      <li>(c) http://www.opera.com/help/tutorials/security/cookies/ (Opera);</li>
      <li>(e) https://support.apple.com/kb/PH21411 (Safari); and</li>
      <li>(f) https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</li>
    </ul>

    <p>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
    <p>If you block cookies, you might not be able to use all the features on our websites.</p>

    <h3>7. Data Retention and Deletion</h3>

    <p>We will not retain data longer than is necessary to fulfill the purposes for which it was collected or as
      required by applicable laws or regulations.</p>
    <p>We may retain aggregated data, which cannot identify an individual or device and is used for purposes of
      reporting and analysis, for as long as commercially necessary.</p>
    <p>We retain identifiable data, which is directly collected for purposes of providing our service to Customers or
      serving content to end-users, for no longer than twenty-five (25) months from the end-user’s last interaction with
      our Service, after which time we will either de-identify the data by removing unique identifiers or aggregating
      the data, or simply delete it.</p>

    <h3>8. Children</h3>

    <p>The Service is not directed to children under 13. We do not knowingly collect Personal Information from anyone
      under 13 years of age. If we determine upon collection that a user is under 13, we will not use or maintain
      his/her Personal Information without the parent/guardian ’s consent. If we become aware that we have unknowingly
      collected Personal Information from a child under the age of 13, we will make reasonable efforts to delete such
      information from our records.</p>

    <p>If you’re a kid, please don’t use any of our Services.</p>

    <h3>9. Security of Your Information</h3>

    <p>We maintain tight controls over all the data we collect, retaining it in secured databases with limited and
      controlled access rights, to ensure it is secure.</p>

    <p>Please remember that unfortunately, the transmission of information via the internet is not completely secure, so
      although we will do our best to protect your personal data, we cannot guarantee the security of your data
      transmitted via the Service; any transmission is at your own risk.</p>

    <h3>10. Other Sites</h3>

    <p>The Service may contain links to other sites that we do not own or operate. This includes links from customers,
      sponsors and partners We do not control, recommend or endorse and are not responsible for these sites or their
      content, products, services or privacy policies or practices. These other web sites may send their own cookies to
      your Device, they may independently collect data or solicit Personal Information and may or may not have their own
      published privacy policies. You should also independently assess the authenticity of any site that appears or claims
      that it is a part of the Service.</p>

    <h3>11. Consent to Processing and Transfer of Information</h3>

    <p>Given that we are an international business, our use of your information may involve the transmission of data on an
      international basis. If you are located in the European Union, please be aware that information we collect may be
      transferred to and processed outside the European Union. By using the Service, or providing us with any information,
      you consent to the collection, processing, maintenance and transfer of such information in and to the United States
      and other applicable territories in which the privacy laws may not be as comprehensive as or equivalent to those in
      the country where you reside and/or are a citizen.</p>

    <h3>12. Changes</h3>

    <p>Please note, we may modify information presented via the Service and/or this Privacy Policy from time to time. You
      should check back here periodically to see if the Privacy Policy has been updated. We will always show the date of
      the latest modification of the Privacy Policy at the bottom of the page so you can tell when it was last revised.</p>

    <h3>13. Contact Us</h3>

    <p>We regularly review our compliance with this Privacy Policy. Questions, comments and requests regarding this Privacy
      Policy are welcomed and should be summitted using contactLink</p>

    <p>This policy was last revised November 20th 2020.</p>
  </div>
</template>

<script>
import {defineComponent, inject} from 'vue';

export default defineComponent({
  name: 'PrivacyPolicy',
  setup() {
    const appName = inject('appName');

    return {
      appName
    };
  }
});
</script>