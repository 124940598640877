import { createRouter, createWebHistory } from 'vue-router';
import AppList from "@/components/AppList";
import AppDetails from "@/components/AppDetails";
import TermsAndConditions from "@/components/TermsAndConditions";
import PrivacyPolicy from "@/components/PrivacyPolicy";

const routes = [
    {
        path: '/terms',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/app',
        name: 'AppDetails',
        component: AppDetails
    },
    {
        path: '/',
        name: 'AppList',
        component: AppList
    }
];

const router = createRouter({
    //history: createWebHashHistory(process.env.BASE_URL), // Use # mode for URLs
    history: createWebHistory(), // Use history mode to avoid hashbang URLs
    routes,
});

export default router;