<template>
  <div id="content" v-if="app">
    <div class="container">
      <div class="app-wrapper">
        <div class="row">
          <div class="col-12">
            <div class="image-wrapper">
              <img :src=app.image :alt=app.name class="app-image-preview">
            </div>
            <h2>{{ app.name }}</h2>
            <h4 class="app-type">{{ app.type }}</h4>
            <div class="rating">
            <span v-for="(star, index) in createStars(app.rating)" :key="index">
                  <span :class="{
                    'star full': star === 'full',
                    'star half': star === 'half',
                    'star empty': star === 'empty'
                  }"></span>
                </span>
            </div>
            <p v-html="newlineToBr(app.description)"></p>
          </div>
          <div class="app-screenshots d-flex flex-row">
            <div class="col-4 px-1 text-center slide" v-for="slide in app.slides" :key="slide">
              <img :src=slide.image :alt=slide.image class="img-fluid">
            </div>
          </div>
          <div class="col-md-12 text-center mt-4">
            <p class="download-button">
              <a :href="createRedirectLink(app.downloadLink, {campaignId, userId})">Install app</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
body {
  font-family: 'Roboto', sans-serif;
  background-color: #F6F6F6;
}

.app-wrapper {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

.image-wrapper {
  float: left;
  margin-right: 20px;
}

.rating img {
  height: 25px;
}

.download-button {
  margin-left: 20px;
  background-color: #183FD4;
  border-radius: 30px;
  margin-top: auto;
  display: inline-block;
}

.download-button a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 1.2em 1.6em;
  line-height: 2.6em;
  font-weight: 700;
}

.app-type {
  color: #8D8D8D;
}

.app-image-preview {
  max-width: 200px;
  height: auto;
}

.app-screenshots .slide img {
  max-width: 250px;
  width: 100%;
}

@media (max-width: 768px) {
  .image-wrapper {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
    text-align: center;
  }

  .download-button {
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: 30px;
    align-self: center;
  }
}
</style>


<script>
import ApiService from '@/services/ApiService';

export default {
  name: 'AppDetails',
  data() {
    return {
      app: null,
      campaignId: 'default_value',
      userId: 'default_value',
    };
  },

  async created() {
    this.campaignId = this.$route.query.campaignId || 'default_value';
    this.userId = this.$route.query.userId || 'default_value';
    let id = this.$route.query.id;
    this.app = await ApiService.getItem(id);
  },
  methods: {}
};
</script>