<template>
  <div id="app">
    <div class="header-wrapper">
      <a href="/#/">
        <img src="/images/store_2x.png" :alt="'logo ' + appName" class="app-image-preview">
      </a>
    </div>
    <router-view/>
    <div class="text-center">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item"><a href="/#/terms">Terms</a></li>
        <li class="list-inline-item"><a href="/#/privacy">Privacy</a></li>
      </ul>
    </div>
  </div>
</template>

<style>
.header-wrapper {
  padding: 30px 20px;
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}
</style>

<script>

import { defineComponent, ref, provide } from 'vue';

export default defineComponent({
  setup() {
    // Define reactive data
    const appName = ref(process.env.VUE_APP_NAME);

    // Provide the reactive data
    provide('appName', appName);

    return {
      appName
    };
  }
});
</script>