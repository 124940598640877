import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export default {
    async getItems() {
        try {
            const response = await apiClient.get('/items');
            return response.data.categories;
        } catch (error) {
            console.error('Error in getItems:', error);
            throw error;
        }
    },
    async getItem(id) {
        let params = {id};
        try {
            const response = await apiClient.get('/item', {params});
            return response.data;
        } catch (error) {
            console.error('Error in getItem:', error);
            throw error;
        }
    },
};